import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { label } from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";

const { REACT_APP_API_ENDPOINT } = process.env;



const AddItem = (props) => {

    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { state } = useLocation(); // Get the state from the previous page

    const [name, setName] = useState("");
    const [partySize, setPartySize] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [notes, setNotes] = useState("");

    console.log(state);
    const handleSubmit = (event) => {
        event.preventDefault();
        doAddItem();
        navigate('/app');     
    }

    const doAddItem = async () => {

        var myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
    
        const raw = JSON.stringify({
            "name": name,
            "party_size": Number(partySize),
            "phone_number": phoneNumber,
        });

        console.log(raw);
    
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
    
        const url = REACT_APP_API_ENDPOINT + "/api/v1/waitlists/" + state.waitlist_id + "/add-item"
        console.log(url)
    
        try {
          const response = await fetch(url, requestOptions); // Replace with your API endpoint
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          console.log(data);
         
        } catch (error) {
          console.log(error.message);
        }
      };


    return (
        <div className="flex-1 overflow-y-auto">
            <div className="p-4 space-y-4">
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-bold text-gray-700 dark:text-gray-300 mb-4">Add Item</h2>
                </div>
                <div className="flex flex-col">
                    <label className="text-gray-700 dark:text-gray-200">First Name</label>
                    <Input 
                        value={name} 
                        onChange={(e) => setName(e.target.value)}
                        type="text" 
                        color="lightBlue" 
                        placeholder="First Name" />

                </div>
                <div className="flex flex-col">
                    <label className="text-gray-700 dark:text-gray-200">Last Name</label>
                    <Input type="text" color="lightBlue" placeholder="Last Name" />
                </div>
                <div className="flex flex-col">
                    <label className="text-gray-700 dark:text-gray-200">Party Size</label>
                    <Input 
                        value={partySize} 
                        onChange={(e) => setPartySize(e.target.value)}
                        type="numeric" 
                        color="lightBlue" 
                        placeholder="Party Size" />
                </div>
                <div className="flex flex-col">
                    <label className="text-gray-700 dark:text-gray-200">Phone Number</label>
                    <Input 
                        value={phoneNumber} 
                        onChange={(e) => setPhoneNumber(e.target.value)}                  
                        type="text" 
                        color="lightBlue" 
                        placeholder="Phone Number" />
                </div>
                <div className="flex flex-col">
                    <label className="text-gray-700 dark:text-gray-200">Notes</label>
                    <Input type="text" color="lightBlue" placeholder="Notes" />
                </div>
                <Button onClick={handleSubmit} className="bg-blue-500 text-white rounded-lg px-4 py-2">Add Item</Button>
            </div>
        </div>
    )
    
}

export default AddItem;

