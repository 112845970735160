
import { BabyIcon } from "./BabyIcon";
import { UmbrellaIcon } from "./UmbrellaIcon";
import { DogIcon } from "./DogIcon";
import { Select } from "@material-tailwind/react";
import { Option } from "@material-tailwind/react";
import React, { useState } from 'react';



export default function ItemDetail({ item }) {

    console.log(item)


    const [isOutside, setOutside] = useState(item.outside)
    const [isPet, setPet] = useState(item.pet)
    const [isBaby, setBaby] = useState(item.baby)
    
    return(
        <div className="p-4">
          <h2 className="text-2xl font-bold text-gray-700 dark:text-gray-300 mb-4">Details</h2>
          <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold">{item.person.first_name}</h3>
            <p className="text-gray-500 dark:text-gray-400">Party of {item.party_size}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">Waiting for 15 minutes</p>
            <div className="flex space-x-2 mt-2">
            <div className="flex items-center space-x-2">
                <UmbrellaIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />                
                <input 
                  checked={isOutside} 
                  onChange={(e) => setOutside(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600" 
                  type="checkbox"  />
              </div>
              <div className="flex items-center space-x-2">
                <BabyIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                <input 
                  checked={isBaby} 
                  onChange={(e) => setBaby(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600" 
                  type="checkbox"  />
              </div>
              <div className="flex items-center space-x-2">
                <DogIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                <input 
                  checked={isPet} 
                  onChange={(e) => setPet(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600" 
                  type="checkbox"  />
              </div>
            </div>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Additional notes: They prefer outdoor seating and have a baby with them.
            </p>
            <div className="mt-4">
              <label className="block text-gray-700 dark:text-gray-300">Status</label>
              <div className="w-full mt-2">
              <Select  variant="standard" label="" selected={() => item.status}>
                <Option>Waiting</Option>
                <Option>Call</Option>
                <Option>Seated</Option>
                <Option>Canceled</Option>
                <Option>No-show</Option>
                </Select>
              </div>
            </div>
            <div className="mt-4">
              <label className="block text-gray-700 dark:text-gray-300">Phone Number</label>
              <input value={item.person.whatsapp_number}  label="Phone Number" className="w-full mt-2" placeholder="Enter phone number"/>              
            </div>
            <div className="mt-4">
              <label className="block text-gray-700 dark:text-gray-300">Email</label>
              <input value={item.person.email} label="Email" className="w-full mt-2" placeholder="Enter email" />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700 dark:text-gray-300">Notification Method</label>
              <div className="w-full mt-2">
              <Select variant="standard" label="" selected={() => "whatsapp"}>
                <Option>SMS</Option> 
                <Option>WhatsApp</Option>
                <Option>iMessage</Option>
                <Option>Call</Option>
                <Option>Push</Option>
              </Select>
              </div>
            </div>
          </div>
        </div>
    );
}


