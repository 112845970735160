/**
 * v0 by Vercel.
 * @see https://v0.dev/t/q0AFPMR2mYb
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Button } from "@material-tailwind/react";

import { Input } from "@material-tailwind/react";
import Waitlist from "./Waitlist";
import ItemDetail from "./ItemDetail";
import React, { useState, useEffect } from 'react';


export default function Main() {

  const [detailItem, setDetailItem] = useState(null);

  const updateDetailClick = (item) => {
    console.log(item);
    setDetailItem(item);
  }



  return (
    <div className="flex h-screen">
      <div className="w-20 flex flex-col items-center justify-between bg-gray-100 dark:bg-gray-800 p-4">
        <div>
          <ListIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
          <SettingsIcon className="w-8 h-8 text-gray-500 dark:text-gray-400" />
        </div>
      </div>
      <Waitlist updateDetail={updateDetailClick} />
      <div className="w-1/3 bg-white dark:bg-gray-900 overflow-y-auto">

      {detailItem === null ? (
        <p></p>
      ) : (
        <ItemDetail item={detailItem} isActive={true} />
      )}
      </div>
    </div>
  )
}



function ListIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="8" x2="21" y1="6" y2="6" />
      <line x1="8" x2="21" y1="12" y2="12" />
      <line x1="8" x2="21" y1="18" y2="18" />
      <line x1="3" x2="3.01" y1="6" y2="6" />
      <line x1="3" x2="3.01" y1="12" y2="12" />
      <line x1="3" x2="3.01" y1="18" y2="18" />
    </svg>
  )
}


function SettingsIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
      <circle cx="12" cy="12" r="3" />
    </svg>
  )
}