import Item from "./Item";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

const { REACT_APP_API_ENDPOINT } = process.env;


const Waitlist = (props) => {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [wl_id, setWlId] = useState(null);

    const navigate = useNavigate(); 

    const [name, setName] = useState("");

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
          fetchData(); // Fetch data every 2 minutes
        }, 60000);

      }, []);

    var waitlists = [];

    
    const fetchData = async () => {
        var myHeaders = new Headers();
        const token = localStorage.getItem('token');

        myHeaders.append("Authorization", "Bearer " + token);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };


        try {
          const response = await fetch(REACT_APP_API_ENDPOINT + '/api/v1/waitlists/', requestOptions); 
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          //setData(data);
          console.log(data);
          waitlists = data;

          // if waitlist has 0 items, return
          if (waitlists.length === 0) {
            setLoading(false);
            return;
          }

          setWlId(waitlists[0].ID);

        } catch (error) {
          setError(error.message);
          setLoading(false);
        }

 

        try {
          const response = await fetch(REACT_APP_API_ENDPOINT + '/api/v1/waitlists/' + waitlists[0].ID, requestOptions); // Replace with your API endpoint
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          setData(data);
          setName(data.name);
          console.log(data);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      };
    

    return (
    <div className="flex-1 overflow-y-auto">
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-700 dark:text-gray-300 mb-4">Waiting List: <b>{name}</b> </h2>
        <Button onClick={() => navigate('/add', { state: { waitlist_id: wl_id}})} className="bg-blue-500 text-white rounded-lg px-4 py-2">Add Item</Button>
      </div>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <div class="flex flex-col space-y-4 ...">
              {data.wait_list_items.map((item,i) => {
                return(
                  <Item updateDetail={props.updateDetail} name={item.person.first_name} party={item.party_size} isCalled={true} i={item}/>
                )
              })}          
              </div>
       
            
          )}
    </div>
  </div>
    );
}

export default Waitlist;