import logo from './logo.svg';
import './App.css';
import Main from './components/Main';
import { BrowserRouter as Router, Route, Redirect, Routes, BrowserRouter } from 'react-router-dom';
import Home from './home';
import Login from './Login';
import AddItem from './AddItem';
import { useEffect, useState } from 'react';


function App() {

  return (

    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/app" element={<Main/>} />
      <Route path="/add" element={<AddItem/>} />
    </Routes>
  </BrowserRouter>
  
    // <Main />
  );
}

export default App;
