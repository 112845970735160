import { Button } from "@material-tailwind/react";
import { DogIcon } from "./DogIcon";
import React, { useState } from 'react';
import { UmbrellaIcon } from "./UmbrellaIcon";
import { BabyIcon } from "./BabyIcon";


const { REACT_APP_API_ENDPOINT } = process.env;


const Item = (props) => {

      const { name, party, isCalled, i } = props;
      const className = i.status == 'called'  ? "bg-green-100 dark:bg-green-900" : "bg-white dark:bg-green-900";


      const [called, setCalled] = useState(i.status == 'called');
      var calledClass = i.status == 'called' ? "bg-green-100 dark:bg-green-900" : "bg-white dark:bg-green-900";

      const [isOutside, setOutside] = useState(i.outside)
      const [isPet, setPet] = useState(i.pet)
      const [isBaby, setBaby] = useState(i.baby)


      const onSeat = (item) => {
        changeState(props, 'finished');
        console.log(item.ID);
      }

      const onCall = (item) => {
         changeState(props, 'called');
         console.log(item.ID);
      
      }

      const waitTime = minutesDiff(new Date(), new Date(i.created_at));

      return (
      <div className={'flex items-center justify-between ' + calledClass + ' p-4 rounded-lg shadow'}>
        <div className="flex-1" onClick={() => props.updateDetail(props.i)}>
          <h3 className="text-lg font-semibold">{props.name}</h3>
          <p className="text-gray-500 dark:text-gray-400">Party of {props.party}</p>
          <p className="text-sm text-gray-500 dark:text-gray-400">Waiting for {waitTime} minutes</p>
          <div className="flex space-x-2 mt-2">    

          {  isOutside ? (
            <UmbrellaIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
          ) : () => {}
          }

          {  isPet ? (
            <DogIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
          ) : () => {}
          }
            
            {  isBaby ? (
              <BabyIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
            ) : () => {}
            }
          </div>
        </div>
        <div className="flex items-center space-x-2">
        {i.status == 'called' ? (
        <p></p>
      ) : (
        <Button onClick={() => onCall(props.i)}  className="w-24"   variant="outline" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-24">
        Call
      </Button>
        )}
        <Button onClick={() => onSeat(props.i)}  className="w-24" class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-24">
             Seat
          </Button>
        </div>
      </div>
        )
    }

  export default Item;


  const changeState = async (props, status) => {

    const token = localStorage.getItem('token');
    console.log(props.i.wait_list_id);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    var raw = JSON.stringify({
      "status": status
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const url = REACT_APP_API_ENDPOINT + '/api/v1/waitlists/' + props.i.wait_list_id + '/item/' + props.i.ID;

    try {
      const response = await fetch(url, requestOptions); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      console.log(data);

    } catch (error) {
      console.log(error.message);
    }
  };


  function minutesDiff(dateTimeValue2, dateTimeValue1) {
    var differenceValue =(dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000;
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
 }